import "./App.css";
import Home from "./page/Home";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import NoInternet from "./components/NoInternet";
import MobileErrorPage from "./components/MobileErrorPage";

function App() {
  const isTvScreen = useMediaQuery("(min-width: 1000px)");

  const [status, setStatus] = useState(true);
  useEffect(() => {
    function changeStatus() {
      setStatus(navigator.onLine);
    }
    window.addEventListener("online", changeStatus);
    window.addEventListener("offline", changeStatus);
    return () => {
      window.removeEventListener("online", changeStatus);
      window.removeEventListener("offline", changeStatus);
    };
  }, []);

  return (
    <>
      {status ? (
        <div>{isTvScreen ? <Home /> : <MobileErrorPage />}</div>
      ) : (
        <NoInternet />
      )}
    </>
  );
}

export default App;
